import { AxiosRequestConfig } from "axios";

const polygonBetaTesterInterceptor = (config: AxiosRequestConfig) => {
  let _config = config;
  if (config.headers) {
    _config = {
      ...config,
      headers: {
        ...config.headers,
        "x-polygon-beta-tester": String(true), // "true" or "false"
      },
    };
  }
  return _config;
};

export default polygonBetaTesterInterceptor;
