/**
 * Development is localhost
 * Staging is the QA environment deployed as Production
 *
 * For Development debugging is enabled
 * For Development & Staging we use development database
 * For Production we use production database, no debugging
 */

export const isDev = process.env.NODE_ENV !== "production";

export const isStaging = process.env.GATSBY_ENVIRONMENT_NAME === "staging";

export const isProd = !isDev && !isStaging;

type EnvironmentVariables = {
  GOOGLE_MAPS_KEY: string;
  FIREBASE_API_KEY: string;
  FIRESTORE_URL: string;
  MESSAGING_SENDER_ID: string;
  FIREBASE_APP_ID: string;
  FIREBASE_MEASUREMENT_ID: string;
  AUTH0: { DOMAIN: string; CLIENT_ID: string; CLIENT_KEY: string };
};

export const env: EnvironmentVariables = {
  GOOGLE_MAPS_KEY: process.env.GATSBY_GOOGLE_MAPS_KEY || "",
  FIREBASE_API_KEY: process.env.GATSBY_FIREBASE_API_KEY || "",
  FIRESTORE_URL: process.env.GATSBY_FIRESTORE_URL || "",
  MESSAGING_SENDER_ID: process.env.GATSBY_MESSAGING_SENDER_ID || "",
  FIREBASE_APP_ID: process.env.GATSBY_FIREBASE_APP_ID || "",
  FIREBASE_MEASUREMENT_ID: process.env.GATSBY_FIREBASE_MEASUREMENT_ID || "",
  AUTH0: {
    DOMAIN: process.env.GATSBY_AUTH0_DOMAIN || "",
    CLIENT_ID: process.env.GATSBY_AUTH0_CLIENT_ID || "",
    CLIENT_KEY: process.env.GATSBY_AUTH0_CLIENT_KEY || "",
  },
};
