import { AxiosResponse } from "axios";
import jwt_decode from "jwt-decode";

import api from "~/utils/api/api";
import clientAuth0 from "~/utils/api/auth0";
import { env } from "~/utils/environment";
import { JWTDecoded, LoginResponse } from "~/utils/interfaces/Auth0";

class AuthenticationService {
  login = async (authorizeData: { email: string; password: string }): Promise<JWTDecoded> => {
    const url = "oauth/token";
    const res = await clientAuth0.post<LoginResponse>(url, {
      grant_type: "http://auth0.com/oauth/grant-type/password-realm",
      client_id: env.AUTH0.CLIENT_ID,
      client_secret: env.AUTH0.CLIENT_KEY,
      realm: "Username-Password-Authentication",
      audience: "https://api.saludando.cl",
      username: authorizeData.email.trim().toLowerCase(),
      password: authorizeData.password,
      scope: "offline_access",
    });
    const user = jwt_decode(res.data.access_token) as JWTDecoded;
    localStorage.setItem("isLoggedIn", "true");
    localStorage.setItem("token", res.data.access_token);
    localStorage.setItem("refresh_token", res.data.refresh_token);
    localStorage.setItem("user", JSON.stringify(user));
    return user;
  };

  refreshAccessToken = async (): Promise<JWTDecoded> => {
    const url = `oauth/token`;
    const response = await clientAuth0.post<LoginResponse>(url, {
      grant_type: "refresh_token",
      client_id: env.AUTH0.CLIENT_ID,
      client_secret: env.AUTH0.CLIENT_KEY,
      audience: "https://api.saludando.cl",
      refresh_token: localStorage.getItem("refresh_token"),
    });
    const user = jwt_decode(response.data.access_token) as JWTDecoded;
    localStorage.setItem("isLoggedIn", "true");
    localStorage.setItem("token", response.data.access_token);
    localStorage.setItem("refresh_token", response.data.refresh_token);
    localStorage.setItem("user", JSON.stringify(user));
    return user;
  };

  requestRecoverPassword = async (email: string): Promise<AxiosResponse<any>> => {
    return api.post("users/request_recover_password/", {
      email,
    });
  };

  setNewPassword = async (
    email: string,
    verification_code: string,
    password_1: string,
    password_2: string,
  ): Promise<AxiosResponse<any>> => {
    return api.post("users/set_new_password/", {
      email,
      verification_code,
      password_1,
      password_2,
    });
  };
}

const authenticationService = new AuthenticationService();

export default authenticationService;
