import { FilterReducer } from "~/utils/interfaces/Filters";

export const filterReducer: FilterReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INITDATE":
      return {
        ...state,
        initDate: action.payload,
      };
    case "UPDATE_FINISHDATE":
      return {
        ...state,
        finishDate: action.payload,
      };
    case "UPDATE_NURSE":
      return {
        ...state,
        nurse: action.payload,
      };
    case "UPDATE_BOOKED":
      return {
        ...state,
        booked: action.payload,
      };
    case "UPDATE_CONTACTED":
      return {
        ...state,
        contacted: action.payload,
      };
    case "UPDATE_ARRIVED":
      return {
        ...state,
        arrived: action.payload,
      };
    case "UPDATE_VISITED":
      return {
        ...state,
        visited: action.payload,
      };
    case "UPDATE_RECEIPT":
      return {
        ...state,
        receipt: action.payload,
      };
    case "UPDATE_RESULTS":
      return {
        ...state,
        results: action.payload,
      };
    case "UPDATE_SALESOURCE":
      return {
        ...state,
        salesSource: action.payload,
      };
    case "UPDATE_COUNTRY":
      return {
        ...state,
        country: action.payload,
        region: "Todas",
        comuna: "Todas",
      };
    case "UPDATE_COMMUNE":
      return {
        ...state,
        comuna: action.payload,
      };
    case "UPDATE_REGION":
      return {
        ...state,
        region: action.payload,
        comuna: "Todas",
      };
    case "UPDATE_EXAM_TYPE":
      return {
        ...state,
        exam_type: action.payload,
      };
    case "UPDATE_STATUS":
      return {
        ...state,
        status: action.payload,
      };
    case "UPDATE_IS_OPERATIVE":
      return {
        ...state,
        is_operative: action.payload,
      };
    case "UPDATE_PAYMENT_STATUS":
      return {
        ...state,
        payment_status: action.payload,
      };
    case "UPDATE_FINISHED":
      return {
        ...state,
        finished: action.payload,
      };
    case "UPDATE_NEEDS_ASSISTANCE_STATUS":
      return {
        ...state,
        needs_assistance_status: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
