import { AxiosRequestConfig } from "axios";

import useAddressStore from "~/stores/AddressStore";

const addressGeolocationInterceptor = (config: AxiosRequestConfig) => {
  const currentAddress = useAddressStore.getState().currentAddress;
  let _config = config;
  if (currentAddress && config.headers) {
    const { latitude, longitude } = currentAddress;
    _config = {
      ...config,
      headers: {
        ...config.headers,
        "x-address-latitude": latitude,
        "x-address-longitude": longitude,
      },
    };
  }
  return _config;
};

export default addressGeolocationInterceptor;
