import React, { useEffect, useState } from "react";

import { Button, createTheme, ThemeProvider } from "@material-ui/core";
import { globalHistory } from "@reach/router";
import { Link, navigate } from "gatsby";
import { FaBookMedical, FaClinicMedical, FaUser, FaWpforms } from "react-icons/fa";
import { FiPackage } from "react-icons/fi";
import { IoCloudOffline } from "react-icons/io5";
import {
  MdDashboard,
  MdDataUsage,
  MdDiscount,
  MdMap,
  MdPayments,
  MdShoppingCart,
  MdSupervisorAccount,
} from "react-icons/md";
import { TiArrowRepeat } from "react-icons/ti";

import Flex from "~/components/Containers/Flex";
import LoadingError from "~/components/Loaders/LoadingError";
import Navbar from "~/components/nav/Navbar";
import FilterStore from "~/components/Stores/FilterStore";
import { Content, Logo, MainLayoutWrap, NavCell, Sidebar } from "~/styles/Layout/MainLayout";
import authenticationService from "~/utils/api/v1/authenticationService";
import Auth from "~/utils/auth/auth";
import { isProd } from "~/utils/environment";

const auth = new Auth();

const logoAsset = require(`~/assets/branding/examedi-logo-2023.png`).default;
export const theme = createTheme({
  palette: {
    primary: {
      light: "#4eb9ec",
      main: "#039Be5",
      dark: "#027cb7",
      contrastText: "#001f2d",
      warning: "#ffc302",
      success: "#00ff00",
      error: "#FF0505",
    },
    secondary: {
      main: "#FF6D70",
    },
  },
});

const navs = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <MdDashboard style={{ color: "var(--primary)" }} />,
  },
  {
    url: `https://${isProd ? "" : "staging-"}ops.examedi.com/telehealths/`,
    name: "Telemedicina",
    icon: <MdDashboard style={{ color: "var(--primary)" }} />,
    external: true,
    requiredRole: "employee",
  },
  {
    path: "/users",
    name: "Usuarios",
    icon: <FaUser style={{ color: "var(--primary)" }} />,
  },
  {
    path: "/patients",
    name: "Pacientes",
    icon: <FaBookMedical style={{ color: "var(--primary)" }} />,
  },
  {
    path: "/nurse-payments",
    name: "Pagos HT",
    icon: <MdPayments style={{ color: "var(--primary)" }} />,
    requiredRole: "nurse_payment_admin",
  },
  {
    path: "/capacity",
    name: "Capacity",
    icon: <MdDataUsage style={{ color: "var(--primary)" }} />,
  },
  {
    path: "/health-team",
    name: "Health Team",
    icon: <MdSupervisorAccount style={{ color: "var(--primary)" }} />,
  },
  {
    path: "/sectors",
    name: "Sectores",
    icon: <MdMap style={{ color: "var(--primary)" }} />,
  },
  {
    path: "/laboratory",
    name: "Laboratorios",
    icon: <FaClinicMedical style={{ color: "var(--primary)" }} />,
  },
  {
    path: "/offline-appointment/create",
    name: "Operativos CL",
    icon: <IoCloudOffline style={{ color: "var(--primary)" }} />,
  },
  {
    path: "/offline-appointment-mx/create",
    name: "Offline MX",
    icon: <IoCloudOffline style={{ color: "var(--primary)" }} />,
  },
  {
    path: "/offline-appointment/assisted-schedule",
    name: "Agendamiento asistido",
    icon: <IoCloudOffline style={{ color: "var(--primary)" }} />,
  },
  {
    path: "/checkout-builder/",
    name: "Carrito Maker",
    icon: <MdShoppingCart style={{ color: "var(--primary)" }} />,
  },
  {
    path: "/medical-orders",
    name: "Órdenes médicas",
    icon: <FaWpforms style={{ color: "var(--primary)" }} />,
  },
  {
    path: "/subscriptions",
    name: "Suscripciones",
    icon: <TiArrowRepeat style={{ color: "var(--primary)" }} />,
  },
  {
    path: "/coupons",
    name: "Cupones",
    icon: <MdDiscount style={{ color: "var(--primary)" }} />,
  },
  {
    path: "/new-packs/",
    name: "Packs Nuevos",
    icon: <FiPackage style={{ color: "var(--primary)" }} />,
  },
  {
    path: "/dashboard/totalpack",
    name: "Total Pack",
    icon: <MdDashboard style={{ color: "var(--primary)" }} />,
  },
  {
    path: "/pending",
    name: "Resultados por Revisar",
    icon: <MdMap style={{ color: "var(--primary)" }} />,
  },
  {
    path: "/refunds",
    name: "Reembolsos Cancelaciones",
    icon: <FaWpforms style={{ color: "var(--primary)" }} />,
  },
];

const MainLayout = (props: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showLayout, setShowLayout] = useState(window.location.pathname !== "/");
  const [active, setActive] = useState<string>("Dashboard");

  const fetchRefreshToken = async () => {
    setLoading(true);
    try {
      await authenticationService.refreshAccessToken();
    } catch (err) {
      console.log(err);
      auth.logout(() => {});
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return globalHistory.listen((routeChange) => {
      if (routeChange.action === "PUSH") {
        setShowLayout(routeChange.location.pathname !== "/");
      }
    });
  }, []);

  useEffect(() => {
    const accessToken = auth.getAccessToken();
    const refreshToken = auth.getRefreshToken();
    if ([accessToken, refreshToken].includes("")) {
      setLoading(false);
      return;
    }
    const lastExecution = localStorage.getItem("lastExecutionTimestamp");
    const currentDate = new Date().getTime();
    const twentyFourHours = 24 * 60 * 60 * 1000;
    const hasToRefreshToken = !lastExecution || currentDate - lastExecution >= twentyFourHours;
    if (hasToRefreshToken) {
      fetchRefreshToken();
      localStorage.setItem("lastExecutionTimestamp", currentDate);
    }
    setLoading(false);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <link
        rel="stylesheet"
        href="https://unpkg.com/leaflet@1.9.1/dist/leaflet.css"
        integrity="sha256-sA+zWATbFveLLNqWO2gtiw3HL/lh1giY/Inf1BJ0z14="
        crossOrigin=""
      />
      <script
        src="https://unpkg.com/leaflet@1.9.1/dist/leaflet.js"
        integrity="sha256-NDI0K41gVbWqfkkaHj15IzU7PtMoelkzyKp8TOaFQ3s="
        crossOrigin=""
      />
      <FilterStore>
        <>
          {showLayout && auth.isLoggedIn() && <Navbar />}
          <MainLayoutWrap>
            {auth.isLoggedIn() && (
              <Sidebar>
                <Flex
                  align="center"
                  justify="center"
                >
                  <Logo src={logoAsset} />
                </Flex>
                <div style={{ marginTop: 20 }}>
                  {navs.map((item, i) => {
                    const { requiredRole } = item;
                    if (requiredRole && !auth.hasRole(requiredRole)) {
                      return null;
                    }
                    if (item.external) {
                      return (
                        <a
                          href={item.url}
                          style={{ textDecoration: "none" }}
                          key={i}
                        >
                          <NavCell
                            style={{
                              backgroundColor: active === item.name ? "whitesmoke" : "white",
                            }}
                          >
                            {item.icon} <h6>{item.name}</h6>
                          </NavCell>
                        </a>
                      );
                    }
                    return (
                      <Link
                        to={item.path}
                        style={{ textDecoration: "none" }}
                        key={i}
                      >
                        <NavCell
                          key={i}
                          style={{
                            backgroundColor: active === item.name ? "whitesmoke" : "white",
                          }}
                          onClick={() => {
                            setActive(item.name);
                          }}
                        >
                          {item.icon} <h6>{item.name}</h6>
                        </NavCell>
                      </Link>
                    );
                  })}
                  <NavCell>
                    <Button onClick={() => auth.logout(async () => navigate("/"))}>Cerrar Sesión</Button>
                  </NavCell>
                </div>
              </Sidebar>
            )}
            <Content
              showLayout={showLayout}
              style={{ overflowX: "auto" }}
            >
              {loading && (
                <LoadingError
                  loading={loading}
                  error={false}
                />
              )}
              {!loading && props.children}
            </Content>
          </MainLayoutWrap>
        </>
      </FilterStore>
    </ThemeProvider>
  );
};

export default MainLayout;
