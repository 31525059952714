import styled from "styled-components";

export const MainLayoutWrap = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-areas: "sidebar content";
  grid-template-columns: min-content 1fr;
`;

export const Logo = styled.img`
  width: 60%;
  margin: 0px auto;
  height: 24px;
  object-fit: contain;
  margin-top: 15px;
`;

export const Sidebar = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  grid-area: sidebar;
  background-color: var(--whitesmoke);
  border-right: 2px solid whitesmoke;
  width: 225px;
  overflow: auto;
`;

export const Content = styled.div`
  ${(props: { showLayout: boolean }) => (props.showLayout ? "margin-left: 225px;" : "")}
  ${(props: { showLayout: boolean }) => (props.showLayout ? "margin-top: 60px;" : "")}
  padding: 3rem;
  grid-area: content;
  overflow: scroll;
  background-color: whitesmoke;
  h2 {
    font-weight: 400;
  }
`;

export const NavCell = styled.div`
  display: flex;
  padding: 15px 0px 15px 20px;
  align-items: center;
  text-decoration: none !important;
  h6 {
    margin-left: 20px;
    font-weight: 500;
    text-decoration: none !important;
  }

  &:hover {
    background-color: whitesmoke;
    cursor: pointer;
  }
`;
