import styled from "styled-components";

const LoaderErrorContainer = styled.div`
  padding: 8rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export default LoaderErrorContainer;
