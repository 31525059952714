import { AxiosRequestConfig } from "axios";

import addressGeolocationInterceptor from "~/utils/api/interceptors/addressGeolocation";
import authorizationTokenInterceptor from "~/utils/api/interceptors/authorizationToken";
import polygonBetaTesterInterceptor from "~/utils/api/interceptors/polygonBetaTester";

type HandlerFunction<T> = (args: T) => T;

export type InterceptorFunction = (config: AxiosRequestConfig) => AxiosRequestConfig;

/**
 * Function that imitates the same behaviour of flow from lodash,
 * used for passing and processing a value from function to function
 * in an array of functions.
 * @param funcs Array of functions where all receive the same typed arguments
 * @returns function that receives as param the param that will be processed by all functions
 */
export function flow<T>(funcs: Array<HandlerFunction<T>>): HandlerFunction<T> {
  return function handler<K extends T>(args: K): K {
    let _ = args;
    for (const func of funcs) {
      try {
        _ = func(_) as K;
      } catch (error) {
        console.log(`[flow]: failed probably due to server error: ${error}`);
      }
    }
    return _;
  };
}

export { addressGeolocationInterceptor, authorizationTokenInterceptor, polygonBetaTesterInterceptor };
