import axios, { AxiosRequestConfig } from "axios";

import {
  InterceptorFunction,
  addressGeolocationInterceptor,
  authorizationTokenInterceptor,
  flow,
  polygonBetaTesterInterceptor,
} from "~/utils/api/interceptors";

const API_URL = process.env.GATSBY_API_URL;
const api = axios.create({
  baseURL: API_URL,
});

/**
 * Axios interceptors are configured here. Add any new interceptors to the array
 * inside the function. The function to be passed must receive a config object
 * as the only parameter. Note that this runs in the client only.
 */
api.interceptors.request.use((_config) => {
  const interceptors: InterceptorFunction[] = [
    authorizationTokenInterceptor,
    addressGeolocationInterceptor,
    polygonBetaTesterInterceptor,
  ];
  const config = flow(interceptors)(_config) as AxiosRequestConfig;
  return config;
});

export default api;
