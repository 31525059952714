import { AxiosRequestConfig } from "axios";

const authorizationTokenInterceptor = (config: AxiosRequestConfig) => {
  const isUser = localStorage.hasOwnProperty("user");
  const token = localStorage.getItem("token");
  let _config = config;
  if (isUser && token && config.headers) {
    _config = {
      ...config,
      headers: {
        ...config.headers,
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
  }
  return _config;
};

export default authorizationTokenInterceptor;
