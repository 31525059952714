import axios from "axios";

import { env } from "~/utils/environment";

const clientAuth0 = axios.create({
  baseURL: `https://${env.AUTH0.DOMAIN}.auth0.com/`,
});

/**
 * Axios interceptors are configured here. Add any new interceptors to the array
 * inside the function. The function to be passed must receive a config object
 * as the only parameter. Note that this runs in the client only.
 */
export default clientAuth0;
