import React, { createContext, useReducer } from "react";

import * as moment from "moment";

import { FilterReducer, Filters } from "~/utils/interfaces/Filters";
import { filterReducer } from "~/utils/reducers/filterReducers";

moment.locale("es");

const defaultFilters: Filters = {
  initDate: moment(),
  finishDate: moment(),
  nurse: "Todos",
  booked: "Todos",
  contacted: "Todos",
  arrived: "Todos",
  visited: "Todos",
  receipt: "Todos",
  results: "Todos",
  salesSource: "todos",
  country: "Chile",
  comuna: "Todas",
  region: "Todas",
  exam_type: "Todos",
  status: "Todos",
  is_operative: false,
  payment_status: "Todos",
  needs_assistance_status: "Todos",
  finished: "Todos",
};

const FilterStore = (props: { children: JSX.Element }): JSX.Element => {
  const [filters, filtersDispatch]: [Filters, FilterReducer] = useReducer(filterReducer, defaultFilters);

  return (
    <FiltersContext.Provider value={[filters, filtersDispatch] as [Filters, FilterReducer]}>
      {props.children}
    </FiltersContext.Provider>
  );
};

export const FiltersContext = createContext(defaultFilters);

export default FilterStore;
