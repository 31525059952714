import React, { useEffect, useState } from "react";

import { globalHistory } from "@reach/router";
import { navigate } from "gatsby";
import SearchBar from "material-ui-search-bar";
import { stringify } from "query-string";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { encodeQueryParams, StringParam } from "serialize-query-params";
import styled from "styled-components";

import Auth from "~/utils/auth/auth";

const auth = new Auth();

const SpacedSearchBar = styled(SearchBar)`
  margin-left: 2rem;
  width: 350px;
`;

const MobileNavDisplay = styled.div`
  content: "";
  background-color: white;
  position: fixed;
  overflow: scroll;
  padding-bottom: 100px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const MobileNavLogo = styled.div`
  border-bottom: 1px solid #020b34;
  display: flex;
  align-items: center;
  padding: 15px 30px;
  background-color: #020b34;
  justify-content: space-between;
`;

const MobileNavContent = styled.div`
  padding: 30px;
  width: 100%;
  h6 {
    color: #020b34;
    text-transform: uppercase;
  }
`;

const DesktopNav = styled.div`
  height: 100%;
  position: relative;
  @media only screen and (max-width: 870px) {
    display: none;
  }
`;

const Logo = styled.img`
  &:hover {
    cursor: pointer;
  }
  @media only screen and (min-width: 501px) {
    width: 150px;
    height: auto;
  }
  @media only screen and (max-width: 500px) {
    width: 100px;
    height: auto;
  }
`;

const NavStyle = styled.div`
  border-bottom: 1px solid #e1ebff;
  height: 60px;
  padding: 0px 5%;
  display: flex;
  width: calc(100% - 225px);
  margin-left: 225px;
  align-items: center;
  background-color: white;
  z-index: 40;
  position: fixed;
  ${!auth.isLoggedIn() && "display: none;"}

  .active {
    background-color: whitesmoke;
    border-bottom: 4px solid #e17a40;
  }
`;

const MobileNav = styled.div`
  position: relative;
  @media only screen and (min-width: 871px) {
    display: none;
  }
`;

const NavbarContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MobileNavCell = styled.div`
  width: 100%;
  padding: 15px 0px;
  @media only screen and (min-width: 601px) {
    &:hover {
      background-color: whitesmoke;
    }
  }
  color: #020b34;
  position: relative;
  display: flex;
  font-weight: 300;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

const ArrowRight = styled.div`
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  margin-right: 30px;
  border-left: 10px solid;
  border-left-color: #f15f5e;
`;

const DesktopNavDisplay = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PrimaryNavbar = () => {
  const [search, setSearch] = useState<string>("");
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(auth.isLoggedIn()); //auth.isLoggedIn() ? true : false);

  // const handleLogoutButton = () => {
  //   auth.logout();
  // }

  /* https://stackoverflow.com/questions/61274365/allow-component-to-detect-route-change-in-gatsby
  use a loggedIn state to update the navbar when needed. On each route change, it will check
  the auth isLoggedIn state and refresh the state of the navbar. */
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        setLoggedIn(auth.isLoggedIn());
      }
    });
  });

  const goToReports = (): void => {
    navigate("/reports");
  };

  const renderMobileNav = () => (
    <MobileNavDisplay>
      <MobileNavLogo>
        <AiOutlineCloseSquare
          style={{ color: "white" }}
          fontSize={36}
          onClick={() => {
            setShowMobileNav(!showMobileNav);
          }}
        />
      </MobileNavLogo>
      <MobileNavContent>
        {loggedIn ? <></> : <></>}
        <MobileNavCell onClick={goToReports}>
          <ArrowRight></ArrowRight>
          Cerrar sesión
        </MobileNavCell>
      </MobileNavContent>
    </MobileNavDisplay>
  );

  const renderDesktopNav = () => {
    return (
      <DesktopNavDisplay>
        {window.location.pathname.startsWith("/users") && (
          <SpacedSearchBar
            value={search}
            onChange={(newValue) => {
              setSearch(newValue);
            }}
            placeholder="Email, Nombre o RUT"
            onRequestSearch={() => {
              const encodedQuery = encodeQueryParams({ search: StringParam }, { search: search });
              navigate(`/users?${stringify(encodedQuery)}`);
              setSearch("");
            }}
          />
        )}
        {window.location.pathname.startsWith("/patients") && (
          <SpacedSearchBar
            value={search}
            onChange={(newValue) => {
              setSearch(newValue);
            }}
            placeholder="Email, Nombre o RUT"
            onRequestSearch={() => {
              const encodedQuery = encodeQueryParams({ search: StringParam }, { search: search });
              navigate(`/patients?${stringify(encodedQuery)}`);
              setSearch("");
            }}
          />
        )}
        {!(window.location.pathname.startsWith("/users") || window.location.pathname.startsWith("/patients")) && (
          <SpacedSearchBar
            value={search}
            onChange={(newValue) => {
              setSearch(newValue);
            }}
            placeholder="Email, Nombre o RUT"
            onRequestSearch={() => {
              const encodedQuery = encodeQueryParams({ search: StringParam }, { search: search });
              navigate(`/dashboard?${stringify(encodedQuery)}`);
              setSearch("");
            }}
          />
        )}
      </DesktopNavDisplay>
    );
  };

  return (
    <NavStyle id="navbar">
      <NavbarContent>
        <span>
          <MobileNav>
            {showMobileNav && renderMobileNav()}
            <GiHamburgerMenu
              onClick={() => {
                setShowMobileNav(!showMobileNav);
              }}
            />
          </MobileNav>
          <DesktopNav>{renderDesktopNav()}</DesktopNav>
        </span>
      </NavbarContent>
    </NavStyle>
  );
};

export default PrimaryNavbar;
