import { create } from "zustand";
import { persist } from "zustand/middleware";

import { ParsedAddress } from "~/utils/interfaces/Address";
import { CreateStore, PersistStore } from "~/utils/interfaces/store";
import { AddressSlice } from "~/utils/interfaces/store/address";

const createAddressSlice: CreateStore<AddressSlice> = (set, get) => ({
  currentAddress: null,
  setCurrentAddress: (address: ParsedAddress) => set({ currentAddress: address }),
  clear: () => set({ currentAddress: null }),
});

const useAddressStore = create<AddressSlice>(
  (persist as unknown as PersistStore<AddressSlice>)(createAddressSlice, {
    name: "address",
    getStorage: () => localStorage,
  }),
);

export default useAddressStore;
